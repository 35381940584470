import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "templates padding"
  }, [_c('h1', [_vm._v("Vorlagen")]), _c('h2', [_vm._v("Gebäude")]), _c('small', [_vm._v("Felder verschieben: mit Maus anklicken und ziehen!")]), _vm._l(_vm.buildingEvaluation, function (btype, ix) {
    return [_c('div', {
      staticClass: "card"
    }, [_c('h3', [_vm._v(_vm._s(btype.name))]), _vm._l(btype.values, function (b, id) {
      return [_c('div', {
        staticClass: "xlabel"
      }, [_c('h4', {
        staticClass: "label bold mt-4"
      }, [_vm._v(_vm._s(b.name))]), _c('drop-list', {
        attrs: {
          "items": b.values,
          "mode": "cut"
        },
        on: {
          "reorder": function reorder($event) {
            return $event.apply(b.values);
          },
          "insert": _vm.insert
        },
        scopedSlots: _vm._u([{
          key: "item",
          fn: function fn(_ref) {
            var item = _ref.item,
              reorder = _ref.reorder,
              index = _ref.index;
            return [_c('drag', {
              key: item,
              attrs: {
                "data": "item"
              },
              on: {
                "cut": function cut($event) {
                  return _vm.remove(b.values, item);
                }
              }
            }, [_c('div', [_c('label', {
              staticClass: "px-4"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model.lazy.trim",
                value: b.values[index],
                expression: "b.values[index]",
                modifiers: {
                  "lazy": true,
                  "trim": true
                }
              }, {
                name: "debounce",
                rawName: "v-debounce",
                value: 1500,
                expression: "1500"
              }],
              staticClass: "input _medium",
              attrs: {
                "type": "text"
              },
              domProps: {
                "value": b.values[index]
              },
              on: {
                "change": function change($event) {
                  _vm.$set(b.values, index, $event.target.value.trim());
                },
                "blur": function blur($event) {
                  return _vm.$forceUpdate();
                }
              }
            }), _c('button', {
              staticClass: "btn _clear",
              on: {
                "click": function click($event) {
                  return b.values.splice(index, 1);
                }
              }
            }, [_c('i', {
              staticClass: "icon-times _redp"
            })])])])])];
          }
        }], null, true)
      })], 1), _c('div', {
        staticClass: "px-4"
      }, [_c('button', {
        staticClass: "btn",
        on: {
          "click": function click($event) {
            return b.values.push('');
          }
        }
      }, [_c('i', {
        staticClass: "icon-add2"
      })])])];
    })], 2)];
  }), _c('div', {
    staticClass: "card"
  }, [_c('h2', [_vm._v("Grundlagen: Hauptseite ")]), _vm._l(_vm.beBasics, function (g, gix) {
    return [_c('div', {
      staticClass: "input-field"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.beBasics[gix],
        expression: "beBasics[gix]"
      }],
      staticClass: "input",
      attrs: {
        "type": "text",
        "placeholder": ""
      },
      domProps: {
        "value": _vm.beBasics[gix]
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.beBasics, gix, $event.target.value);
        }
      }
    })])];
  })], 2), _c('div', {
    staticClass: "card"
  }, [_c('h2', [_vm._v("Grundlagen: Allgemein")]), _c('h3', [_vm._v("Allgemein")]), _c('TipTap', {
    attrs: {
      "content": _vm.beGeneral,
      "ckey": "general"
    }
  }), _c('h3', [_vm._v("Gebäude")]), _c('TipTap', {
    attrs: {
      "content": _vm.beGeneral,
      "ckey": "buildings"
    }
  }), _c('h3', [_vm._v("Wertermittlung")]), _c('TipTap', {
    attrs: {
      "content": _vm.beGeneral,
      "ckey": "valuation"
    }
  }), _c('h3', [_vm._v("Unterlagen - Anlagen")]), _c('TipTap', {
    attrs: {
      "content": _vm.beGeneral,
      "ckey": "attachments"
    }
  })], 1), true ? _c('div', {
    staticClass: "noselect",
    class: {
      _opened: true
    },
    attrs: {
      "id": "savebtn"
    },
    on: {
      "click": function click($event) {
        !_vm.loading && _vm.updateAll();
      }
    }
  }, [!_vm.loading ? _c('i', {
    staticClass: "icon-upload"
  }) : _vm.loading ? _c('div', {
    staticClass: "loading-spinner _small tac"
  }) : _vm._e(), _c('br'), _c('span', {
    staticClass: "bold"
  }, [_vm._v("SPEICHERN")])]) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };